import React from "react";
import { FaBullseye, FaHandsHelping } from "react-icons/fa"; // Import icons
import { useInView } from "react-intersection-observer";
import rihabPicture from "../../assets/rihab_picture.jpeg";
import badisPicture from "../../assets/badis_picture.jpg";
import empowerment_img from "../../assets/empowerment.png";
import accessibility_img from "../../assets/idea.webp";
import community_img from "../../assets/community.png";
import innovation_img from "../../assets/online_interview.png";

const Section = ({ children }) => {
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.15 });

    return (
        <div
            ref={ref}
            className={`transition-opacity duration-700 transform ${
                inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-8"
            }`}
        >
            {children}
        </div>
    );
};

const AboutUs = () => {
    return (
        <div className="max-w-[1340px] mx-auto flex flex-col items-center p-10 space-y-12">
            {/* Header Section */}
            <Section>
                <section className="text-center space-y-4">
                    <h1 className="text-[28px] sm:text-[40px] font-bold bg-gradient-to-r from-[#E1E1E1] to-[#929292] bg-clip-text text-transparent">
                        About Us
                    </h1>
                    <p className="text-[#E9E9E9B2] text-[16px] max-w-[800px] mx-auto">
                        At FAANG, we’re not just rethinking interviews—we’re reinventing them. Our mission is to empower candidates by turning AI into a tool for skill-building, confidence, and self-presentation.
                    </p>
                </section>
            </Section>

            {/* Our Vision Section */}
            <Section>
                <section className="flex flex-col items-center space-y-4 text-center max-w-[800px]">
                    <div className="flex items-center space-x-2">
                        <FaBullseye className="text-indigo-500 text-2xl" /> {/* Vision Icon */}
                        <h2 className="text-[22px] font-semibold text-white">Our Vision</h2>
                    </div>
                    <p className="text-[#E9E9E9B2] text-[16px]">
                        We envision stress-free interviews as opportunities to shine. FAANG’s AI creates an interview experience that’s engaging, personalized, and empowering for all job seekers.
                    </p>
                </section>
            </Section>

            {/* Our Approach Section */}
            <Section>
                <section className="flex flex-col items-center space-y-4 text-center max-w-[800px]">
                    <div className="flex items-center space-x-2">
                        <FaHandsHelping className="text-indigo-500 text-2xl" /> {/* Approach Icon */}
                        <h2 className="text-[22px] font-semibold text-white">Our Approach</h2>
                    </div>
                    <p className="text-[#E9E9E9B2] text-[16px]">
                        At FAANG, we’re more than just an AI tool—we’re your personal interview coach. Our platform adapts to your strengths, areas for improvement, and specific job roles, helping you refine skills and tailor responses with confidence.
                    </p>
                </section>
            </Section>

            {/* What We Value Section */}
            <Section>
                <section className="bg-[#2c2a48] p-10 rounded-[12px] w-full max-w-[1100px] text-center space-y-8">
                    <h2 className="text-[22px] font-semibold text-white">Our Values</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 text-left">
                        <div className="flex flex-col items-center space-y-2 text-[#E9E9E9B2]">
                            <img src={empowerment_img} alt="Empowerment" className="w-[105px] h-[105px] rounded-full object-cover" />
                            <h3 className="text-white font-semibold">Empowerment</h3>
                            <p className="text-[14px] text-center">
                                Helping every candidate feel prepared and confident.
                            </p>
                        </div>
                        <div className="flex flex-col items-center space-y-2 text-[#E9E9E9B2]">
                            <img src={accessibility_img} alt="Accessibility" className="w-[105px] h-[105px] rounded-full object-cover" />
                            <h3 className="text-white font-semibold">Accessibility</h3>
                            <p className="text-[14px] text-center">
                                Simple, intuitive design for all job seekers.
                            </p>
                        </div>
                        <div className="flex flex-col items-center space-y-2 text-[#E9E9E9B2]">
                            <img src={community_img} alt="Community" className="w-[105px] h-[105px] rounded-full object-cover" />
                            <h3 className="text-white font-semibold">Community</h3>
                            <p className="text-[14px] text-center">
                                Building a space for support and shared growth.
                            </p>
                        </div>
                        <div className="flex flex-col items-center space-y-2 text-[#E9E9E9B2]">
                            <img src={innovation_img} alt="Innovation" className="w-[105px] h-[105px] rounded-full object-cover" />
                            <h3 className="text-white font-semibold">Innovation</h3>
                            <p className="text-[14px] text-center">
                                Constantly evolving AI for smarter preparation.
                            </p>
                        </div>
                    </div>
                </section>
            </Section>

            {/* Meet the Team Section */}
            <Section>
                <section className="mb-16">
                    <h2 className="text-3xl font-bold text-white text-center mb-10">
                        Meet the Team
                    </h2>
                    <p className="text-gray-300 text-center max-w-xl mx-auto leading-relaxed mb-12">
                        We’re a diverse, innovative team on a mission to transform the interview experience.
                    </p>
                    <div className="flex flex-wrap justify-center gap-12">
                        {[
                            {
                                img: rihabPicture,
                                name: "Rihab Lajmi",
                                role: "Co-founder & CEO",
                            },
                            {
                                img: badisPicture,
                                name: "Badis Zormati",
                                role: "Co-founder & CTO",
                            },
                        ].map((member, index) => (
                            <div key={index} className="flex flex-col items-center">
                                <div className="w-40 h-40 mb-4">
                                    <img
                                        src={member.img}
                                        alt={member.name}
                                        className="w-full h-full object-cover rounded-full border-4 border-indigo-600"
                                    />
                                </div>
                                <h3 className="text-xl font-semibold text-white mb-1">
                                    {member.name}
                                </h3>
                                <p className="text-gray-400">{member.role}</p>
                            </div>
                        ))}
                    </div>
                </section>
            </Section>

            {/* Call to Action */}
            <Section>
                <section className="text-center">
                    <h2 className="text-3xl font-bold text-white mb-6">
                        Join the FAANG Community
                    </h2>
                    <p className="text-gray-300 max-w-xl mx-auto leading-relaxed mb-8">
                        Ready to transform your interview experience? Join us today and take the first step towards landing your dream job.
                    </p>
                    <a
                        href="https://discord.gg/ZWzDHszV"
                        target="_blank"
                        className="bg-indigo-600 text-white py-3 px-8 rounded-full font-semibold hover:bg-indigo-500 transition-colors"
                        rel="noopener noreferrer"
                    >
                        Get Started
                    </a>
                </section>
            </Section>
        </div>
    );
};

export default AboutUs;

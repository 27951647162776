import { useEffect, useRef, useState } from "react";
import "../CSS/buttons.css"
import gradient_background from "../../../assets/lottie/gradien_background.json"
import EWOR from "../../../assets/EWOR-logo.png"
import { FaDiscord } from 'react-icons/fa';
import { IoIosRocket } from "react-icons/io";
import { useInView } from 'react-intersection-observer';
import Lottie from "lottie-react";

const TypewriterText = ({ text, speed = 200, pauseDuration = 4000 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let timer;

    if (isDeleting) {
      // Deleting the text
      timer = setTimeout(() => {
        setDisplayedText(prevText => prevText.slice(0, -1));
        if (displayedText === '') {
          setIsDeleting(false);
          setIndex(prevIndex => (prevIndex + 1) % text.length);
        }
      }, speed);
    } else {
      // Typing the text
      if (index < text.length) {
        timer = setTimeout(() => {
          setDisplayedText(text.slice(0, index + 1));
          setIndex(prevIndex => prevIndex + 1);
        }, speed);
      } else {
        // Pausing before starting to delete
        timer = setTimeout(() => {
          setIsDeleting(true);
        }, pauseDuration);
      }
    }

    return () => clearTimeout(timer);
  }, [displayedText, isDeleting, index, text, speed, pauseDuration]);

  return <span>{displayedText}</span>;
};


const HeaderComponent = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.1, // Trigger when 10% of the iframe is in view
  });

  return (
    <section className="w-full flex flex-col justify-center items-center">

      <h1 className="landing-page-tile w-full text-slate-300 text-6xl mt-16 text-wrap text-center font-bold leading-tight max-sm:text-3xl lg:w-[70%]">
        Build your custom AI Interviewer <br />
        "<TypewriterText text="Buddy" />"

      </h1>
      <p className="landing-subtitle text-slate-400 text-center my-4 max-sm:text-xs animate-fade-in-up">
        <span className="block max-lg:inline">
          Create personalized interview experiences that adapt to your unique style and needs
        </span>
        <span className="block max-lg:inline">
          It empowers you to design faster than ever.
        </span>
      </p>

      {/* <div className="flex items-center space-x-4 my-4 max-sm:flex-col justify-center">
          <div className="rounded-3xl flex flex-col justify-center items-center p-px no-underline transition-all duration-400 ease-in-out relative overflow-hidden shadow-[0_0_50px_rgba(87,220,134,0.5)] hover:shadow-[0_0_50px_10px_rgba(87,220,134,0.5)] max-sm:my-6 w-full">
            <div className="mx-auto flex items-center justify-center">
              <div className="w-full  relative flex cursor-pointer items-center overflow-hidden rounded-3xl py-[0.5px] px-[0.6px]">
                <div className="animate-rotate absolute inset-0 rounded-full bg-[conic-gradient(#fff_20deg,transparent_120deg)]"></div>

                <div className="bg-[#101217] relative py-3 px-7 text-slate-300 rounded-3xl flex flex-col justify-center items-center p-px no-underline transition-all duration-400 ease-linear  max-sm:px-4 w-full">
                  Start builing free
                </div>
              </div>
            </div>
          </div>
          <div className="text-slate-300 border-[0.5px] ring-0 py-3 px-8 rounded-3xl">
            Join Our Discord
          </div>
        </div> */}

      <span>
        <svg
          width="126"
          height="126"
          viewBox="0 0 126 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="14" width="16" height="28" rx="8" fill="#5F5BCD" className="bar bar1">
            <animate
              attributeName="height"
              values="28; 48; 28"
              dur="2s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              values="14; -6; 14"
              dur="2s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="22" y="4" width="16" height="48" rx="8" fill="#5F5BCD" className="bar bar2">
            <animate
              attributeName="height"
              values="48; 28; 48"
              dur="2s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              values="4; 14; 4"
              dur="2s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="44" y="11" width="16" height="34" rx="8" fill="#5F5BCD" className="bar bar3">
            <animate
              attributeName="height"
              values="34; 54; 34"
              dur="2s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              values="11; -9; 11"
              dur="2s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="66" width="16" height="56" rx="8" fill="#5F5BCD" className="bar bar4">
            <animate
              attributeName="height"
              values="56; 36; 56"
              dur="2s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              values="0; 10; 0"
              dur="2s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="88" y="7" width="16" height="42" rx="8" fill="#5F5BCD" className="bar bar5">
            <animate
              attributeName="height"
              values="42; 22; 42"
              dur="2s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              values="7; 17; 7"
              dur="2s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="110" y="14" width="16" height="28" rx="8" fill="#5F5BCD" className="bar bar6">
            <animate
              attributeName="height"
              values="28; 48; 28"
              dur="2s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="y"
              values="14; -6; 14"
              dur="2s"
              repeatCount="indefinite"
            />
          </rect>
        </svg>

      </span>


      <div style={{ marginTop: "24px" }} className="flex space-x-4">
        <div
          onClick={() => window.location.href = "https://dashboard.faangai.com"}
          className="pulse-button button-1 cursor-pointer bg-transparent ring-1 z-10 ring-[#615fb0] relative py-4 px-8 text-slate-300 rounded-30 flex justify-center items-center shadow-[0_0_50px_rgba(97,95,176,0.5)] hover:shadow-[0_0_50px_10px_rgba(97,95,176,0.5)] max-sm:py-[10px] max-sm:px-[15px] max-sm:text-xs"
        >
          <IoIosRocket className="mr-2" /> {/* Rocket icon */}
          Start building free

          <div
            className="bg-transparent z-30 rounded-30 absolute top-[60%] left-[50%] transform -translate-x-1/2 -translate-y-1/2"
            style={{
              background: "radial-gradient(circle, rgba(119,116,213,0.3) 0%, rgba(16,18,23,0) 100%)",
              boxShadow: "0px 0px 100px 10px rgba(119,116,213,0.4)",
              backdropFilter: "blur(20px)",
            }}
          ></div>
        </div>



        <a href="https://discord.gg/ZWzDHszV" target="_blank"
          style={{
            background: "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(152, 195, 189, 0.1) 100%)"
          }}
          className="bg-black/20 backdrop-blur-[4px] button-2 flex justify-center items-center text-slate-300 border border-white/[0.5]  ring-0 py-4 px-8 rounded-30 max-sm:py-[10px] max-sm:px-[15px] max-sm:text-xs"
        >
          <FaDiscord className="mr-2" />
          Join Our Discord
        </a>
      </div>


      <div className="mx-auto my-12 flex gap-[16px] flex-wrap">
        <div className="relative flex cursor-pointer items-center overflow-hidden rounded-lg py-[0.7px] px-[0.5px]">
          <a href='https://www.producthunt.com/products/faang' target="_blank">
          <div style={{
            background: "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(152, 195, 189, 0.1) 100%)"
          }}
            className=" border border-white/[0.08] bg-black/20 backdrop-blur-[4px] space-x-6 relative py-2 px-5 text-slate-300 rounded-lg flex justify-center items-center p-px no-underline transition-all duration-400 ease-linear">
            <div className="flex space-x-2 items-center">
              <div className="bg-[#FF6154] w-6 h-6 sm:w-8 sm:h-8 rounded-full text-slate-50 text-lg sm:text-2xl flex justify-center items-center font-semibold">
                P
              </div>
              <div className="flex flex-col">
                <span className="text-[8px] sm:text-[10px] font-bold">FEATURED ON</span>
                <span className="text-base sm:text-lg font-bold">Product Hunt</span>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center">
              <span>
                <svg width="7" height="6" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.7774 9.28441H0.567383L5.67192 0.00512695L10.7774 9.28441Z" fill="white" />
                </svg>
              </span>
              <span className="text-xs sm:text-sm">107</span>
            </div>
          </div>
          </a>
        </div>

        <div className="relative flex cursor-pointer items-center overflow-hidden rounded-lg py-[0.7px] px-[0.5px]">
          <div style={{
            background: "radial-gradient(112.32% 83.8% at 50.41% 50%, rgba(0, 0, 0, 0) 0%, rgba(152, 195, 189, 0.1) 100%)"
          }}
            className="w-full border border-white/[0.08] bg-black/20 backdrop-blur-[4px] space-x-6 relative py-2 px-5 text-slate-300 rounded-lg flex justify-center items-center p-px no-underline transition-all duration-400 ease-linear">
            <div className="flex space-x-2 items-center">
              <div className="bg-white w-6 h-6 sm:w-8 sm:h-8 rounded-full flex justify-center items-center">
                <img src={EWOR} className="w-full h-full" />
              </div>
              <div className="flex flex-col">
                <span className="text-[8px] sm:text-[10px] font-bold">Backed by</span>
                <span className="text-base sm:text-lg font-bold">EWOR</span>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <img className="soundwave" src={soundwaveImage} alt="Soundwave" /> */}
      {/* <iframe  style={{
          width: "100%",
          height: "600px",
          position: "absolute",
          top: "-2%",  // You might want to adjust this
          opacity: 0.4,
          border: "none", // Ensures no border around the iframe
        }}
        src="https://lottie.host/embed/dd412662-04d1-4c74-8558-c93d07d5c24d/4XQVDdefXr.json"></iframe> */}




      <Lottie
        animationData={gradient_background}
        loop={true}  // Set to true if you want it to loop
        style={{
          width: "100%",   // Match iframe width
          height: "600px", // Match iframe height
          position: "absolute",  // Match iframe positioning
          top: "-10%",  // Same adjustment as iframe
          opacity: 0.4,
          transition: 'opacity 3s ease-in-out',  // Same transition for smooth fading
          border: "none",  // Ensure no border (though not really needed for Lottie)
        }}
      />

    </section>
  );
};

export default HeaderComponent;
